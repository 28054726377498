<template>
    <div>
        <LoaderDefault v-if="isLoading" />
        <div v-else>
            <DefaultIndexHeader :routePush="'/RegisterNew'" :titleHeader="'Registrar nueva noticia'" />
            <div class="row">
                <div class="container-card-new card" v-for="(item, index) in listAllNews" :key="index">
                    <div class="wrapper" :style="{ 'background-image': 'url(' + item.images[0].urlImage + ')' }">
                        <div class="header">
                            <div class="date">
                                <span class="month">{{ item.date }}</span>
                            </div>
                        </div>
                        <div class="data">
                            <div class="content">
                                <span class="author">EmSilvia</span>
                                <div class="container-title-new">
                                    <h1 class="title">
                                        {{ item.title }}
                                    </h1>
                                    <p class="text"> {{ item.description }}</p>
                                </div>
                                <div class="footer-card-new">
                                    <router-link class="button bg-success"
                                        :to="{ name: 'DetailNew', params: { _id: item._id } }">
                                        Ver
                                    </router-link>
                                    <router-link class="button bg-warning"
                                        :to="{ name: 'EditNew', params: { _id: item._id } }">
                                        Editar
                                    </router-link>
                                    <a href="#" class="button bg-danger" @click="showConfirmation(item._id)">Eliminar</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DefaultIndexHeader from '../../../components/Headers/DefaultIndexHeader.vue';
import LoaderDefault from '../../../components/Loaders/LoaderDefault.vue';
import { db, storage } from '../../../../firebaseConfig';

export default {
    components: {
        LoaderDefault,
        DefaultIndexHeader,
    },
    data() {
        return {
            listAllNews: [],
            isLoading: false
        };
    },
    async mounted() {
        await this.getAllNews()
        this.styleActionCards()
    },
    methods: {
        styleActionCards() {
            var zindex = 10;
            var cards = document.querySelectorAll('.card');

            cards.forEach(function (card) {
                card.addEventListener('click', function (e) {
                    e.preventDefault();
                    var isShowing = false;

                    if (this.classList.contains('show')) {
                        isShowing = true;
                    }

                    var cardsWrapper = document.querySelector('div.cards');

                    if (cardsWrapper && cardsWrapper.classList.contains('showing')) {
                        var showingCard = document.querySelector('div.card.show');
                        if (showingCard) {
                            showingCard.classList.remove('show');
                        }

                        if (isShowing) {
                            cardsWrapper.classList.remove('showing');
                        } else {
                            card.style.zIndex = zindex;
                            card.classList.add('show');
                        }

                        zindex++;

                    } else {
                        if (cardsWrapper) {
                            cardsWrapper.classList.add('showing');
                        }
                        card.style.zIndex = zindex;
                        card.classList.add('show');

                        zindex++;
                    }
                });
            });
        },

        async getAllNews() {
            this.isLoading = true
            await db.collection('news').get().then((res) => {
                const news = [];
                res.forEach((doc) => {
                    news.push(doc.data());
                });
                this.listAllNews = news;
                this.isLoading = false
            })
        },

        showConfirmation(doc_id) {
            this.$swal
                .fire({
                    title: 'Eliminar noticia',
                    text: '¿Estás seguro de que deseas realizar esta acción?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#45A825",
                    cancelButtonText: 'Cancelar',
                })
                .then((result) => {
                    if (result.value) this.deleteStorageFolder(doc_id);
                });
        },

        async deleteStorageFolder(childName) {
            this.isLoading = true
            const folderRef = await storage.ref()
                .child('imgNews')
                .child(childName)
            try {
                const folderFiles = await folderRef.listAll();
                await Promise.all(folderFiles.items.map((item) => item.delete()));
                await db.collection('news')
                    .doc(childName)
                    .delete()
                    .then(() => {
                        this.isLoading = false
                        this.$swal
                            .fire({
                                title: 'Acción exitosa',
                                text: 'Noticia eliminada exitosamente',
                                icon: 'success',
                                showCancelButton: false,
                                confirmButtonText: 'Aceptar',
                                confirmButtonColor: "#45A825",
                            })
                        return this.getAllNews()
                    })
            } catch (error) {                
                this.isLoading = false
            }
        }
    },


}
</script>

<style lang="css">
.container-title-new {
    /* padding: 4px; */
    /* background-color: rgba(0, 0, 0, 0.035); */
    width: 100%;
    border-radius: 10px;
    /* backdrop-filter: blur(10px) opacity(0.8); */
    overflow: hidden;
    margin-bottom: 10px;

}

.title {
    font-weight: 300;
}

.row {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.card {
    padding: 0 1.7rem;
    width: 450px;
}

.card .wrapper {
    background-color: #fff;
    min-height: 500px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.2);
    background-repeat: no-repeat;
    width: 100%;
    background-color: #1890ff;
    margin-bottom: 40px;
}

.card .wrapper:hover .data {
    transform: translateY(0);
}

.card .data {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(calc(70px + 1em));
    transition: transform 0.3s;
}

.card .data .content {
    padding: 1em;
    position: relative;
    z-index: 1;
}

.card .author {
    font-size: 12px;
}

.card .title {
    margin-top: 10px;
}

.card .text {
    height: 70px;
    margin: 0;
}

.container-card-new .wrapper:hover .menu-content span {
    transform: translate(-50%, -10px);
    opacity: 1;
}

.container-card-new .header {
    color: #fff;
    padding: 1em;
}

.container-card-new .header::before,
.container-card-new .header::after {
    content: '';
    display: table;
}

.container-card-new .header::after {
    clear: both;
}

.container-card-new .header .date {
    float: left;
    font-size: 12px;
}

.container-card-new .data {
    color: #fff;
    transform: translateY(calc(70px + 4em));
}

.container-card-new .title {
    color: #fff;
}

.container-card-new .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    border-radius: 8px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    height: 30px;
}

.footer-card-new {
    display: flex;
    justify-content: space-between;
}
</style>